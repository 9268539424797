import _layoutMode from "isotope-layout/js/layout-mode";
import _packery from "packery";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*!
 * Packery layout mode v2.0.1
 * sub-classes Packery
 */

/*jshint browser: true, strict: true, undef: true, unused: true */
(function (window, factory) {
  'use strict'; // universal module definition

  if (exports) {
    // CommonJS
    exports = factory(_layoutMode, _packery);
  } else {
    // browser global
    factory(window.Isotope.LayoutMode, window.Packery);
  }
})(window, function factory(LayoutMode, Packery) {
  'use strict'; // create an Outlayer layout class

  var PackeryMode = LayoutMode.create("packery");
  var proto = PackeryMode.prototype;
  var keepModeMethods = {
    _getElementOffset: true,
    _getMeasurement: true
  }; // inherit Packery prototype

  for (var method in Packery.prototype) {
    // do not inherit mode methods
    if (!keepModeMethods[method]) {
      proto[method] = Packery.prototype[method];
    }
  } // set packer in _resetLayout


  var _resetLayout = proto._resetLayout;

  proto._resetLayout = function () {
    (this || _global).packer = (this || _global).packer || new Packery.Packer();
    (this || _global).shiftPacker = (this || _global).shiftPacker || new Packery.Packer();

    _resetLayout.apply(this || _global, arguments);
  };

  var _getItemLayoutPosition = proto._getItemLayoutPosition;

  proto._getItemLayoutPosition = function (item) {
    // set packery rect
    item.rect = item.rect || new Packery.Rect();
    return _getItemLayoutPosition.call(this || _global, item);
  }; // needsResizeLayout for vertical or horizontal


  var _needsResizeLayout = proto.needsResizeLayout;

  proto.needsResizeLayout = function () {
    if (this._getOption("horizontal")) {
      return this.needsVerticalResizeLayout();
    } else {
      return _needsResizeLayout.call(this || _global);
    }
  }; // point to mode options for horizontal


  var _getOption = proto._getOption;

  proto._getOption = function (option) {
    if (option == "horizontal") {
      return (this || _global).options.isHorizontal !== undefined ? (this || _global).options.isHorizontal : (this || _global).options.horizontal;
    }

    return _getOption.apply((this || _global).isotope, arguments);
  };

  return PackeryMode;
});

export default exports;